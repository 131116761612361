<template>
  <div style="background:#F6F7FB;">
    <div class="homeTop">
      <div class="homeTop-title">企微工具</div>
      <div class="homeTop-view">
        <img :src="user.user.avatar" alt="" />
        <div class="homeTop-view_name">{{ user.user.name }}</div>
      </div>
      <div class="homeTopCenter" @click="router('statistics')">
        <div class="homeTopCenter_T">
          <div class="homeTopCenter_T-item">
            <div class="T-item_name">我的客户</div>
            <div class="T-item_num">{{ user.contact_num }}</div>
          </div>
          <div class="homeTopCenter_T-item" style="position:relative;">
            <div class="T-item_border left-border"></div>
            <div class="T-item_name">最近活跃</div>
            <div class="T-item_num">{{ user.active_num }}</div>
            <div class="T-item_border right-border"></div>
          </div>
          <div class="homeTopCenter_T-item">
            <div class="T-item_name">待沟通</div>
            <div class="T-item_num">{{ user.connect_num }}</div>
          </div>
        </div>
        <div class="homeTopCenter_B">
          <div class="homeTopCenter_B-item" style="position: relative;">
            <div>昨日新增</div>
            <div class="B-item_add">+{{ user.contact_num_today }}</div>
            <div class="item_B-leftLine"></div>
          </div>
          <div class="homeTopCenter_B-item">
            <div>昨日流失</div>
            <div class="B-item_green">-{{ user.lost_num }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="list">
      <div
        class="listItem"
        v-for="(item, index) in list"
        :key="index"
        @click="router('list', item.path, index)"
      >
        <img :src="item.icon" alt="" />
        <div class="listItem-name">{{ item.name }}</div>
      </div>
    </div>

    <!-- 待办事项 -->
    <div class="need" v-if="calendar.length > 0">
      <div class="need-name">待办事项</div>
      <div
        class="needItem"
        v-for="(item, index) in calendar"
        :key="index"
        @click="goRouter(item)"
      >
        <div class="needItem-L">
          <div>{{ item.title }}</div>
          <div class="needItem-L_btn">
            {{ item.status == 1 ? "待完成" : "完成" }}
          </div>
        </div>
        <div class="needItem-more">
          <div class="needItem-friend">
            {{ item.type == 1 ? "朋友圈任务" : "跟进任务" }}
          </div>
        </div>
        <div class="needItem-time">发布时间 {{ item.createtime }}</div>
        <div class="needItem-time">截止时间 {{ item.endtime }}</div>
      </div>
    </div>

    <div class="while"></div>
    <tabbar :current="0" :isShow="6"></tabbar>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import Tabbar from "@/components/tabbar.vue";

export default {
  name: "Home",
  components: { Tabbar },
  data() {
    return {
      list: [
        {
          icon: require("../assets/imgs/source.png"),
          name: "素材",
          path: "/Material",
        },
        {
          icon: require("../assets/imgs/code.png"),
          name: "活码",
          path: "/LiveCode",
        },
        {
          icon: require("../assets/imgs/activity.png"),
          name: "活动",
        },
        {
          icon: require("../assets/imgs/task.png"),
          name: "任务",
          path: "/Task",
        },
      ],
      user: {
        user: {
          avatar: require("../assets/imgs/icon.png"),
          name: "",
        },
      },
      calendar: [],
    };
  },
  mounted() {
    let that = this;
    // this.$route.query.userid = 'DuJi'
    if (this.$route.query.userid) {
      that.getList(this.$route.query.userid);
      localStorage.setItem("userid", this.$route.query.userid);
    } else {
      if (localStorage.getItem("userid")) {
        that.getList(localStorage.getItem("userid"));
      }
    }
  },
  methods: {
    goRouter(item) {
      this.$router.push({
        path: "/TaskDetail",
        query: {
          work_task_id: item.work_task_id,
        },
      });
    },
    router(type, path, index) {
      if (type == "statistics") {
        this.$router.push("/Statistics");
      } else if (type == "list") {
        if (index == 2) {
          Toast("敬请期待");
        } else {
          this.$router.push(path);
        }
      }
    },
    async getList(userid) {
      let res = await request._getHome({ userid });
      console.log("首页 res", res);
      if (_.toInteger(res.code) === 1) {
        this.user = res.data;
        this.calendar = res.data.calendar || [];
      }
    },
  },
};
</script>

<style scoped>
.homeTop {
  background: url(../assets/imgs/homeBg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 529px;
}

.homeTop-title {
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #101f48;
  padding: 92px 0 0 41px;
}

.homeTop-view {
  display: flex;
  align-items: center;
  margin: 52px 0 0 41px;
}

.homeTop-view img {
  width: 120px;
  height: 120px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 25px;
}

.homeTop-view_name {
  font-size: 40px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #101f48;
}

.homeTopCenter {
  width: 690px;
  height: 275px;
  background: linear-gradient(180deg, #e8efff 0%, #ffffff 19%, #ffffff 100%);
  box-shadow: 0px 10px 50px 1px rgba(161, 182, 248, 0.27);
  border-radius: 24px 24px 24px 24px;
  margin: 65px auto 0;
}

.homeTopCenter_T {
  display: flex;
  justify-content: space-between;
  width: 630px;
  padding: 41px 0 30px 0;
  margin: 0 auto;
  border-bottom: 1px solid #d6e5ee;
}

.homeTopCenter_T-item {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.T-item_name {
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin-top: 7px;
}

.T-item_num {
  font-size: 60px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #101f48;
}

.T-item_border {
  height: 59px;
  width: 1px;
  background: #d6e5ee;
  position: absolute;
}

.left-border {
  left: 0;
  bottom: 0;
}

.right-border {
  right: 0;
  bottom: 0;
}

.homeTopCenter_B {
  height: 92px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.homeTopCenter_B-item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.item_B-leftLine {
  height: 37px;
  width: 1px;
  position: absolute;
  right: 0;
  top: 28px;
  background: #d6e5ee;
}

.B-item_add {
  font-size: 36px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #e1544d;
  margin-left: 8px;
}

.B-item_green {
  font-size: 36px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #58b878;
  margin-left: 8px;
}

.list {
  margin: 160px auto 0;
  width: 690px;
  height: 207px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 1px rgba(161, 182, 248, 0.1);
  border-radius: 24px 24px 24px 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listItem img {
  width: 74px;
  height: 74px;
}

.listItem-name {
  font-size: 26px;
  margin-top: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.need {
  width: 690px;
  margin: 25px auto;
  padding: 0 0 30px 0;
  min-height: 679px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.need-name {
  font-size: 30rpx;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  padding: 25px 0 24px 30px;
}

.needItem {
  width: 629px;
  padding: 0 0 36px;
  background: #f6f7fb;
  border-radius: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 16px;
}

.needItem-L {
  margin-top: 36px;
  display: flex;
  width: 569px;
  align-items: center;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.needItem-L_btn {
  width: 79px;
  height: 36px;
  background: #0558f4;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 17px;
  color: #ffffff;
}

.needItem-more {
  width: 569px;
  margin: 10px 0 18px 0;
}

.while {
  height: 150px;
}

.needItem-friend {
  padding: 10px 10px;
  float: left;
  background: #e5ecfb;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.needItem-time {
  font-size: 26px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  width: 569px;
  margin-top: 6px;
}
</style>
